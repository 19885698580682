#link {
  width: 100%;
  height: 100%;
}
.github:hover {
  color: #80a0b7;
}

.mdl-card__title-text {
  color: #387db1;
  width: 100%;
}

.card-title:hover {
  color: black;
}

.mdl-card__supporting-text {
  font-family: 'Merriweather', serif !important;
}

.card {
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 600px) {
  .card {
    margin: 20px auto !important;
  }
}

#O1 {
  background-image: url('../portfolio/images/BoardGame.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#O2 {
  background-image: url('../portfolio/images/Brooklyn.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#O3 {
  background-image: url('../portfolio/images/Restaurant.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#C1 {
  background-image: url('../portfolio/images/Jammming.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#C2 {
  background-image: url('../portfolio/images/Ravenous.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#U1 {
  background-image: url('../portfolio/images/PixelArt.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#U2 {
  background-image: url('../portfolio/images/Rock.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#S1 {
  background-image: url('../portfolio/images/blogApp.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#S2 {
  background-image: url('../portfolio/images/library.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
