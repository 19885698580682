
.text{
    display:flex;

}

.text h3, p{
    margin: 25px !important;
}

.text h3{
    width: 30%;
}

.text p{
    width:65%;
}

.about-me{
    margin-top:40px;
}

@media only screen and (max-width: 974px) {

    .text p{
        width:90%;
    }

    .text h3{
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        width:100%;
    }

    .text h3, p{
        margin:10px !important;
    }

    .text{
        flex-wrap: wrap;
        justify-content: center !important;
    }

}