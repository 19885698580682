.header {
  height: 300px;
  width: 100%;
  background-color: #387db1;
  margin: 0 auto;
  text-align: center;
  padding-top: 150px;
}

.header h1 {
  margin: 0;
  color: rgb(33, 37, 41);
}

.name {
  color: #f9f9f3;
}

.header p {
  font-weight: 700;
  font-size: 20px;
  color: rgb(33, 37, 41);
  margin-bottom: 40px;
}

.header a {
  color: #141414;
  font-weight: 700;
  background-color: #cbd4db;
  border-color: inherit;
  text-decoration: none;
  border-radius: 15px 50px;
  padding: 20px 35px;
}

.header a:hover {
  color: #387db1;
}

/*
 #e0ebe8
*/
