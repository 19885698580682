
html{
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

h1, h2, h3, h4, h5{
  font-family: 'Lora', serif !important;
}

p, a {
  font-family: 'Merriweather', serif !important;

}

#wrapper{
  margin: 0 auto;
  background-color: white;
  z-index: 3;
}
#map{
  /*vh is height of viewport*/
  height: 100vh;
  width: 100%;
}

@media only screen and (min-width: 1240px){
  #wrapper{
    max-width: 1360px;
  }
}

@media only screen and (max-width: 768px){
  h2, h1{
    font-size: 26px !important;
  }

  .header p{
    font-size: 16px !important;
  }

  .header a{
    padding:15px 25px !important;
  }

  .header{
    height: 250px !important;
  }

  .title{
    padding: 35px 0 !important;
  }
}
